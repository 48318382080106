<script lang="ts">
  export let total: number;
  export let current: number;
  export let estimatedRemainingMs: number | null = null;

  function msToMinutesAndSeconds(ms: number) {
    const minutes = Math.floor(ms / 1000 / 60);
    const seconds = Math.floor(ms / 1000) % 60;

    return minutes.toString() + 'm, ' + seconds.toString() + 's';
  }
</script>

<div>
  <div class="mb-1 h-2 w-full rounded-md bg-gray-600">
    <div
      class="h-2 rounded-md bg-pome-alert-success"
      style:width="{(current / total) * 100}%"
    />

    <div class=" -mt-4 flex w-full justify-center font-bold">
      <div class="rounded-xl bg-pome-dark px-2 opacity-80">
        {current} / {total}
      </div>
    </div>
  </div>
  {#if estimatedRemainingMs}
    <div class="text-sm">
      ~{msToMinutesAndSeconds(estimatedRemainingMs)} remaining
    </div>
  {/if}
</div>
