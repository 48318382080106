import Sentry from '$lib/sentry';

export function isValue<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

/**
 * Given an API Response with a Blob and headers, trigger a download for the Blob,
 * reading the filename and content-type from the headers if they exist.
 */
export function downloadBlob(blob: Blob, downloadFileName: string) {
  const url = window.URL.createObjectURL(blob);
  downloadFromURL(url, downloadFileName);
}

/**
 * Given an API Response with a URL, trigger a download for the URL.
 */
export function downloadFromURL(url: string, downloadFileName?: string) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!window || !document) {
    Sentry.captureMessage('downloadBlob: window or document is not defined');
    return;
  }

  // Create a link to download the file
  const link = document.createElement('a');
  link.href = url;
  // If no filename is provided, using `download=""` will tell the browser
  // to try to determine a filename from 'Content-Disposition' header.
  link.download = downloadFileName ?? '';
  document.body.appendChild(link);
  link.click();

  // Cleanup
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}
